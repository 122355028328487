import React, { CSSProperties } from "react";
import { HalController } from "api-web-client";

import { ProductGridPromoSection } from "resources/AudiotekaApi";
import Markdown from "components/markdown";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { breakpoint } from "modules/DesignSystem/breakpoint";

import { trackCTAHomeEvent } from "../../home.utils";
import { Action, BgPicture, Content, Description, Root, TeaserList } from "./ProductGridPromo.styled";
import { PromoTop } from "./PromoTop";

export const ProductGridPromo = ({
  lazyLoading,
  section,
}: {
  lazyLoading: boolean;
  section: ProductGridPromoSection;
}) => {
  const [element] = section.elements;

  const products = section._embedded["app:product"].slice(0, 5).map((product) => HalController.fromObject(product));

  return (
    <Root style={{ color: `#${element.description_color}` } as CSSProperties}>
      <BgPicture>
        <source media={`(min-width: ${breakpoint.tablet})`} srcSet={`${element.background_image_url}?auto=format`} />
        <img loading="lazy" src={`${element.mobile_background_image_url}?auto=format`} alt="" />
      </BgPicture>
      <Content>
        <PromoTop
          color={element.title_color}
          sideButton={
            element.side_button_deeplink
              ? {
                  color: element.side_button_label_color ? `#${element.side_button_label_color}` : null,
                  deeplink: element.side_button_deeplink,
                  label: element.side_button_label,
                }
              : null
          }
          title={element.title}
        />
        <TeaserList
          colorless
          compact
          elements={products}
          lazyLoading={lazyLoading}
          trackingId={element.tracking_id || section.id}
          noPrice
        />
        {element.description ? (
          <Description>
            <Markdown text={element.description} />
          </Description>
        ) : null}
        {element.button_deeplink ? (
          <Action
            {...deeplinkTranslator(element.button_deeplink)}
            style={{ backgroundColor: `#${element.button_color}`, color: `#${element.button_label_color}` }}
            onClick={() => trackCTAHomeEvent(element.title, element.button_label)}
          >
            {element.button_label}
          </Action>
        ) : null}
      </Content>
    </Root>
  );
};
