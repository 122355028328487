import React, { HTMLAttributes } from "react";
import classNames from "classnames";

import css from "./text.module.scss";

const typography = {
  heading1: "h1",
  heading2: "h2",
  heading3: "h3",
  heading4: "h4",
  heading5: "h5",
  heading6: "h6",
  subtitleBig: "p",
  subtitleNormal: "p",
  subtitleSmall: "p",
  bodyBig: "p",
  bodyNormal: "p",
  bodySmall: "p",
  bodyExtraSmall: "p",
} as const;

interface Props extends HTMLAttributes<HTMLElement> {
  as?: Exclude<keyof HTMLElementTagNameMap, "details">;
  type: keyof typeof typography;
}

export function Text({ as, className, type, ...props }: Props) {
  const Tag = as ?? typography[type];

  return <Tag className={classNames(css[type], className)} {...props} />;
}
